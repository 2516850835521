body{
  box-sizing: border-box;
  margin: 0;
  height: 100vh;
  padding: 1rem;
}

#root{
  display: grid;
  place-items: center; 
  height: 95%;
}

@media screen and (min-width: 768px){
  .App{
    height: 85%;
    width: 35%;
  }
  .buttons {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(3,1fr);
  }
  button:nth-child(7){
  grid-column:span 3;
}
  button{
    width: 8rem !important;;
   }

}
.App {
  border-radius: 1rem;
  text-align: center;
  padding: 1rem 1rem;
  box-shadow: 0rem 0.75rem 2.625rem 0rem rgba(40,39,48,0.522);
}
 h1 {
 width: 62%;
font-size: 1rem;
text-align: center;
font-weight: normal;

}

.sub-heading {
  display: grid;
  place-items: center;
  margin: .7rem .5rem;
}

img{
  max-width: 100%;
  vertical-align: top;
  padding-top: 2.7rem;
  width: 55%;
}

.buttons{
    display: grid;
  gap: 1rem;
  place-items: center;
}

button{
  width:12rem;
  height:2.5rem;
  background: transparent;
  border: 1px solid #000;
  border-radius: .4rem;
}



button:hover{
  animation: button_hover .3s forwards;
  color: #fff;
  cursor: pointer;
}

@keyframes button_hover {
  to {  
     box-shadow: rgba(0,0,0) 0px 40px 0px 1px inset;
 }
}
.logo{
  max-width: 100%;
  display: flex;
justify-content: center;
gap: 20px;
margin :2.5rem
}

.iconify {
  border: 1px solid #000;
  border-radius: 1rem;
  padding: 5px;
}

.iconify:hover{
  animation: button_hover .3s forwards;
  color: #fff;
  cursor: pointer;
}